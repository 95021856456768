import type { SxProps, Theme } from "@mui/material/styles";
import type { Maybe } from "../generic";

type Sx = Maybe<SxProps<Theme>>;

type UnArray<TArr> = TArr extends Array<infer U> ? U : TArr;

export const sxHelper = (...sx: Sx[]) => {
  const sxResult: UnArray<SxProps<Theme>>[] = [];

  sx.forEach((_sx) => {
    if (_sx) {
      Array.isArray(_sx) ? sxResult.push(..._sx) : sxResult.push(_sx);
    }
  });

  return sxResult as SxProps<Theme>;
};
