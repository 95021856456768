import { graphql, useStaticQuery } from "gatsby";

export const useMainMenu = () => {
  const { wpMenu } = useStaticQuery<Queries.MainMenuQuery>(graphql`
    query MainMenu {
      wpMenu(locations: { eq: MAIN_MENU }) {
        __typename
        id
        menuItems {
          nodes {
            __typename
            id
            label
            uri
            parentId
            childItems {
              nodes {
                __typename
              }
            }
          }
        }
      }
    }
  `);

  if (!wpMenu?.menuItems?.nodes.length) return [];

  const topLevelItems = wpMenu.menuItems.nodes.filter(
    ({ parentId }) => parentId === null
  );
  const subMenuItems = wpMenu.menuItems.nodes.filter(
    ({ parentId }) => parentId !== null
  );

  return topLevelItems.map(({ id, label, uri, childItems }) => ({
    id,
    content: label,
    target: uri,
    ...(childItems?.nodes.length
      ? {
          subMenu: subMenuItems
            .filter(({ parentId }) => parentId === id)
            .map((submenu) => ({
              id: submenu.id,
              content: submenu.label,
              target: submenu.uri,
            })),
        }
      : {}),
  }));
};

export type MainMenuType = ReturnType<typeof useMainMenu>;
export type SubMenuType = MainMenuType[number]["subMenu"];
