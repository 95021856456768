import React from "react";
import IconButton from "@mui/material/IconButton";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import type { IconButtonProps } from "../iconButtonProps";

export const Email = (props: IconButtonProps) => (
  <IconButton {...props}>
    <AlternateEmailIcon />
  </IconButton>
);
