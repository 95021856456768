import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useTheme } from "@mui/material";

export const FooterAbout = () => {
  const { breakpoints } = useTheme();
  return (
    <Box
      sx={{
        width: 420,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 1,
        color: ({ palette }) => palette.primary.contrastText,
        [breakpoints.down("sm")]: {
          width: "fit-content",
        },
      }}
    >
      <Typography variant="h5" component="p" color="inherit">
        Over ACIC
      </Typography>
      <Typography variant="body2" gutterBottom>
        Wij vormen een interdisciplinair team dat zich bezighoudt met de totale
        zorg voor kinderen met autisme en hun omgeving.
      </Typography>
      <Typography variant="body2">
        Omdat ieder kind uniek is, met zijn eigen unieke persoonlijkheid,
        behoeften, wensen en talenten, biedt Autisme Centrum In Contact alle
        begeleiding op maat.
      </Typography>
    </Box>
  );
};
