import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import type { MobileMainMenuProps } from "./mobileMainMenu.types";
import { Logo } from "../logo";
import { ContactButtons } from "../contactButtons";
import { sxHelper } from "../../utils/sx";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import IconButton from "@mui/material/IconButton";
import { MobileMenuModal } from "../mobileMenuModal";

export const MobileMainMenu = ({ sx }: MobileMainMenuProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  return (
    <Box
      sx={sxHelper(
        {
          display: "flex",
          backgroundColor: ({ palette }) => palette.common.white,
          padding: ({ spacing }) => spacing(1),
        },
        sx
      )}
    >
      <ContactButtons sx={{ marginRight: "auto" }} />
      <Logo
        sx={{
          maxWidth: 100,
          transform: "translateX(-20px)",
        }}
        imgStyle={{
          objectFit: "contain",
        }}
      />
      <IconButton
        sx={{ marginLeft: "auto" }}
        color="primary"
        onClick={handleOpen}
      >
        <MenuSharpIcon />
      </IconButton>
      <MobileMenuModal open={open} close={handleClose} />
    </Box>
  );
};
