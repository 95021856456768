import React from "react";
import Box from "@mui/material/Box";
import { Link } from "../link";
import { useFooterMenu } from "../../hooks/useFooterMenu";
import type { SxProp } from "../../generic";
import { sxHelper } from "../../utils/sx";

import { useTheme } from "@mui/material";

export const FooterMenu = ({ sx }: { sx?: SxProp }) => {
  const footerMenu = useFooterMenu();
  const { breakpoints } = useTheme();

  return (
    <Box
      component="ul"
      sx={sxHelper(
        {
          listStyle: "none",
          margin: 0,
          textAlign: "right",
          [breakpoints.down("sm")]: { textAlign: "left", paddingLeft: 0 },
        },
        sx
      )}
    >
      {footerMenu.map(({ id, content, target }) =>
        target ? (
          <li key={id}>
            <Link to={target} sx={{ fontSize: 18 }}>
              {content}
            </Link>
          </li>
        ) : null
      )}
    </Box>
  );
};
