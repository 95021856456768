import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiProvider } from "@mui/material/styles";
import type { ReactNode } from "react";
import React from "react";
import theme from "./theme";

export const ThemeProvider = ({ children }: { children: ReactNode }) => (
  <MuiProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiProvider>
);
