import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { MainMenu } from "../mainMenu";
import { MobileMainMenu } from "../mobileMainMenu";
import { useIsAtScrollPosition } from "../../hooks/useIsAtScrollPosition";

export const Header = () => {
  const isAtTop = useIsAtScrollPosition();

  return (
    <>
      <AppBar
        color="transparent"
        elevation={0}
        sx={{
          paddingY: ({ spacing }) => spacing(isAtTop ? 2 : 0),
          transition: ({ transitions }) =>
            transitions.create(["padding-top", "padding-bottom"], {
              duration: 100,
            }),
        }}
      >
        <MainMenu
          sx={{
            "@media (max-width: 1180px)": {
              display: "none",
            },
          }}
        />
        <MobileMainMenu
          sx={{
            "@media (min-width: 1181px)": {
              display: "none",
            },
          }}
        />
      </AppBar>
      <Box
        sx={{
          backgroundColor: "transparent",
          height: isAtTop ? "94px" : "72px",
          transition: ({ transitions }) =>
            transitions.create("height", {
              duration: 100,
            }),
        }}
      />
    </>
  );
};
