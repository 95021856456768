import { useState, useEffect } from "react";

export const useIsAtScrollPosition = (offset = 0) => {
  const [isAtScrollPosition, setIsAtScrollPosition] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setIsAtScrollPosition(currentPosition <= offset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offset]);

  return isAtScrollPosition;
};
