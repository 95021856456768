import React from "react";
import { Link } from "./link";
import type { TypographyProps } from "@mui/material/Typography";
import Typography from "@mui/material/Typography";
import { usePrivacyPage } from "../hooks/privacyPage";
import type { SxProps, Theme } from "@mui/material/styles";
import { sxHelper } from "../utils/sx";

export type PrivacyLinkProps = {
  sx?: SxProps<Theme>;
  variant?: TypographyProps["variant"];
};

export const PrivacyLink = ({ sx, variant }: PrivacyLinkProps) => {
  const { title, uri } = usePrivacyPage();

  return uri ? (
    <Typography
      variant={variant}
      component={Link}
      to={uri}
      sx={sxHelper(sx, { "&:hover": { textDecoration: "underline" } })}
    >
      {title}
    </Typography>
  ) : null;
};
