import React from "react";
import type { ReactNode } from "react";
import Box from "@mui/material/Box";
import { Header } from "../header";
import { Footer } from "../footer";

export const Layout = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Header />
    <Box sx={{ flexGrow: 1, padding: 0, display: "grid" }}>{children}</Box>
    <Footer />
  </Box>
);
