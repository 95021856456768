import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import type { ImageProps } from "../image";
import { Img } from "./img";

export const LogoWhite = (props: Omit<ImageProps, "alt" | "image">) => {
  const { file } = useStaticQuery<Queries.LogoWhiteQuery>(graphql`
    query LogoWhite {
      file(relativePath: { eq: "logo_white.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const { gatsbyImageData } = { ...file?.childImageSharp };

  return gatsbyImageData ? <Img {...props} image={gatsbyImageData} /> : null;
};
