import { graphql, useStaticQuery } from "gatsby";

export const useFooterMenu = () => {
  const { wpMenu } = useStaticQuery<Queries.FooterMenuQuery>(graphql`
    query FooterMenu {
      wpMenu(locations: { eq: FOOTER }) {
        __typename
        id
        menuItems {
          nodes {
            __typename
            id
            label
            uri
          }
        }
      }
    }
  `);

  if (!wpMenu?.menuItems?.nodes.length) return [];

  return wpMenu.menuItems.nodes.map(({ id, label, uri }) => ({
    id,
    content: label,
    target: uri,
  }));
};

export type FooterMenuType = ReturnType<typeof useFooterMenu>;
