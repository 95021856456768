import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Modal,
  Slide,
} from "@mui/material";
import React from "react";
import theme from "../../theme/theme";
import { SmallMDDDLink } from "../smallMDDDLink";
import { Link } from "../link";
import { Logo } from "../logo";

// import hook
import { useMainMenu } from "../../hooks/useMainMenu";
import type { MobileMenuModalProps } from "./mobileMenuModal.types";

export const MobileMenuModal = ({ open, close }: MobileMenuModalProps) => {
  const menu = useMainMenu();

  return (
    <Modal
      open={open}
      onClose={close}
      aria-labelledby="open-menu"
      aria-describedby="mobile-menu"
    >
      <Slide in={open}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            outline: 0,
            backgroundColor: ({ palette }) => palette.common.white,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: ({ spacing }) => spacing(3),
              left: ({ spacing }) => spacing(2.5),
              width: {
                xs: 175,
                sm: 200,
              },
              zIndex: 10,
            }}
          >
            <Logo />
          </Box>

          <IconButton
            size="large"
            color="primary"
            onClick={close}
            sx={{
              position: "absolute",
              top: ({ spacing }) => spacing(3.5),
              right: ({ spacing }) => spacing(1),
              zIndex: 10,
            }}
          >
            <CloseRoundedIcon fontSize="large" />
          </IconButton>

          <Box
            sx={{
              position: "absolute",
              top: 116,
              left: 0,
              right: 0,
              bottom: 44,
              display: "flex",
              flexDirection: "column",
              outline: 0,
              backgroundColor: ({ palette }) => palette.extraLight,
              overflowY: "auto",
              boxShadow: `inset 0px 3px 3px -2px rgb(0 0 0 / 20%),
              inset 0px 3px 4px 0px rgb(0 0 0 / 14%),
              inset 0px 1px 8px 0px rgb(0 0 0 / 12%),
              inset 0px -3px 3px -2px rgb(0 0 0 / 18%),
              inset 0px -3px 4px 0px rgb(0 0 0 / 12%),
              inset 0px -1px 8px 0px rgb(0 0 0 / 10%)`,
              paddingTop: ({ spacing }) => spacing(2),
              [theme.breakpoints.down("sm")]: {
                overflowX: "hidden",
              },
            }}
          >
            <MenuList
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: ({ spacing }) => ({
                  xs: spacing(2, 1),
                  md: spacing(6, 1),
                }),
                [theme.breakpoints.down("sm")]: {
                  paddingLeft: 0,
                  paddingBottom: 0,
                },
                li: {
                  listStyle: "none",
                  marginTop: { xs: "-12px", sm: 0 },
                  marginBottom: 0,
                },
              }}
            >
              {menu?.map((menuItem) => {
                const hasChildren = !!menuItem?.subMenu?.length;
                const childNodes = menuItem?.subMenu;

                return [
                  <MenuItem
                    sx={{
                      padding: ({ spacing }) => spacing(0, 2),
                      textTransform: "uppercase",
                      color: ({ palette }) => palette.quaternary.dark,
                      fontSize: "115%",
                      fontWeight: 800,
                      letterSpacing: "0.1rem",
                      justifyContent: "flex-start",
                    }}
                    key={menuItem?.id}
                    onClick={close}
                  >
                    <Link
                      sx={{
                        padding: ({ spacing }) => ({
                          xs: spacing(0, 0, 0, 2),
                          sm: spacing(1, 2, hasChildren ? 0.25 : 1),
                        }),
                        textTransform: "uppercase",
                        color: ({ palette }) => palette.quaternary.dark,
                        fontSize: "115%",
                        fontWeight: 800,
                        letterSpacing: "0.1rem",
                        justifyContent: "flex-start",
                      }}
                      key={menuItem?.id}
                      onClick={close}
                      to={menuItem?.target ?? ""}
                    >
                      {String(menuItem?.content)}
                    </Link>
                  </MenuItem>,
                  hasChildren
                    ? childNodes?.map((childItem, i) => {
                        const length = Number(menuItem?.subMenu?.length) - 1;
                        const end = length === i;

                        return [
                          <MenuItem
                            sx={{
                              padding: ({ spacing }) => ({
                                xs: spacing(0, 0, 0, 6),
                                sm: spacing(0.5, 2, 0.5, 6),
                              }),
                              textTransform: "none",
                              color: ({ palette }) => palette.primary.dark,
                              fontSize: { xs: "90%", sm: "115%" },
                              fontWeight: 500,
                              letterSpacing: "0.1rem",
                              justifyContent: "flex-start",
                            }}
                            key={childItem?.id}
                          >
                            <Link
                              onClick={close}
                              to={childItem.target ?? ""}
                              sx={{
                                color: ({ palette }) => palette.quaternary.main,
                              }}
                            >
                              {String(childItem?.content)}
                            </Link>
                          </MenuItem>,
                          end ? <div style={{ minHeight: 12 }} /> : null,
                        ];
                      })
                    : null,
                ];
              })}
            </MenuList>
          </Box>
          <Box sx={{ marginTop: "auto", width: "100%" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                padding: ({ spacing }) => spacing(2),
                paddingBottom: ({ spacing }) => spacing(1.25),
              }}
            >
              <SmallMDDDLink color="textPrimary" hoverColor="primary.main" />
            </Box>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};
