import React from "react";
import Box from "@mui/material/Box";
import { SubFooter } from "../subFooter";
import { LogoWhite } from "../logo";

import { FooterMenu } from "../footerMenu";
import { FooterAbout } from "../footerAbout";

import { useTheme } from "@mui/material";

export const Footer = () => {
  const { breakpoints } = useTheme();
  return (
    <Box
      component="footer"
      sx={{
        a: {
          color: ({ palette }) => palette.primary.contrastText,
          transition: ({ transitions }) => transitions.create("color"),
          "&:hover": {
            color: ({ palette }) => palette.secondary.light,
          },
        },
      }}
    >
      <Box
        sx={{
          color: ({ palette }) => palette.primary.contrastText,
          backgroundColor: ({ palette }) => palette.primary.main,
          padding: ({ spacing }) => spacing(7, 5),
          display: "flex",
          justifyContent: "space-between",
          [breakpoints.down("sm")]: {
            flexDirection: "column-reverse",
            padding: ({ spacing }) => spacing(5, 1.5),
          },
        }}
      >
        <FooterAbout />

        <LogoWhite
          imgStyle={{
            objectFit: "contain",
          }}
          sx={{
            [breakpoints.down("sm")]: {
              display: "none!important",
            },
          }}
        />

        <Box
          sx={{
            width: 360,
            [breakpoints.down("sm")]: {
              width: "fit-content",
              marginRight: "auto",
              marginBottom: ({ spacing }) => spacing(4),
            },
          }}
        >
          <FooterMenu />
        </Box>
      </Box>
      <Box
        sx={{
          color: ({ palette }) => palette.primary.contrastText,
          backgroundColor: ({ palette }) => palette.primary.dark,
          padding: ({ spacing }) => spacing(1, 3),
          [breakpoints.down("sm")]: {
            padding: ({ spacing }) => spacing(1, 0),
          },
        }}
      >
        <SubFooter />
      </Box>
    </Box>
  );
};
