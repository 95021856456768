import { Typography, Link } from "@mui/material";
import React from "react";
import type { MDDDLinkProps } from "./smallMDDDLink.types";

export const SmallMDDDLink = ({
  color = "primary",
  hoverColor = "primary.light",
}: MDDDLinkProps) => (
  <Typography variant="caption">
    <Link
      href="https://www.mddd.nl"
      rel="author noopener"
      target="_blank"
      color={color}
      sx={{
        textDecoration: "none",
        transition: ({ transitions }) =>
          transitions.create("color", {
            duration: transitions.duration.shortest,
            easing: transitions.easing.easeInOut,
          }),
        "&:hover, &:focus": {
          color: hoverColor,
        },
      }}
    >
      Built by MDDD © {new Date().getFullYear()}
    </Link>
  </Typography>
);
