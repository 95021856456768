import React from "react";
import { useContactInfo } from "../../hooks/contactInfo";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import {
  contvertToMapsLink,
  convertToAddressString,
} from "../../utils/convert";
import { MDDDLink } from "../mdddLink";
import { PrivacyLink } from "../privacyLink";

import { useTheme } from "@mui/material";

const fontSize = 10;

export const SubFooter = () => {
  const { breakpoints } = useTheme();
  const { straat, huisnummer, postcode, plaats } = useContactInfo();
  const addressData = { straat, huisnummer, postcode, plaats };
  const color = "#ffffff";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        paddingX: ({ spacing }) => spacing(2),
      }}
    >
      <Link
        href={contvertToMapsLink(addressData)}
        variant="caption"
        color={color}
        sx={{
          [breakpoints.down("sm")]: {
            fontSize,
          },
        }}
      >
        {convertToAddressString(addressData)}
      </Link>
      <PrivacyLink
        sx={{
          color,
          [breakpoints.down("sm")]: {
            display: "none",
          },
        }}
        variant="caption"
      />
      <MDDDLink
        variant="caption"
        color={color}
        sx={{
          [breakpoints.down("sm")]: {
            fontSize,
          },
        }}
      />
    </Box>
  );
};
