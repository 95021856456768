import {
  createTheme,
  responsiveFontSizes,
  darken,
  lighten,
  getContrastRatio,
} from "@mui/material/styles";

// import types
import type { PaletteColor, PaletteColorOptions } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    extraLight: string;
    tertiary: PaletteColor;
    quaternary: PaletteColor;
  }
  interface PaletteOptions {
    extraLight: string;
    tertiary: PaletteColorOptions;
    quaternary: PaletteColorOptions;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    tertiary: true;
    quaternary: true;
  }
}

const primaryColor = `#E53825`;
const secondaryColor = `#757575`;
const tertiaryColor = `#81AB33`;
const quaternaryColor = `#CD7EB3`;

const contrastThreshold = 4;

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
      light: lighten(secondaryColor, 0.4),
    },
    tertiary: {
      light: lighten(tertiaryColor, 0.3),
      main: tertiaryColor,
      dark: darken(tertiaryColor, 0.3),
      contrastText:
        getContrastRatio(tertiaryColor, "#000") >= contrastThreshold
          ? "#000000"
          : "#ffffff",
    },
    quaternary: {
      light: lighten(quaternaryColor, 0.3),
      main: quaternaryColor,
      dark: darken(quaternaryColor, 0.3),
      contrastText:
        getContrastRatio(quaternaryColor, "#000") >= contrastThreshold
          ? "#000000"
          : "#ffffff",
    },
    extraLight: lighten(primaryColor, 0.7),
  },
  shape: {
    borderRadius: 0,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1366,
      xl: 1500,
    },
  },
});

const typographyCustomization = responsiveFontSizes(
  createTheme(theme, {
    typography: {
      fontFamily: "VAGRoundedLT-Light, serif-sans",
      allVariants: {
        letterSpacing: "0.06rem",
        WebkitFontSmoothing: "antialiased",
      },
      h1: {
        fontSize: "5rem",
        color: theme.palette.primary.dark,
        lineHeight: 0.95,
        fontFamily: "VAGRoundedLT, serif-sans",
      },
      h2: {
        color: theme.palette.primary.dark,
        lineHeight: 0.95,
        fontFamily: "VAGRoundedLT, serif-sans",
      },
      h3: {
        color: theme.palette.primary.dark,
        fontSize: "2.6rem",
        lineHeight: 0.95,
        fontFamily: "VAGRoundedLT, serif-sans",
      },
      h4: {
        color: theme.palette.primary.dark,
        fontSize: "2.2rem",
        lineHeight: 0.95,
        fontFamily: "VAGRoundedLT, serif-sans",
      },
      h5: {
        color: theme.palette.primary.dark,
        fontSize: "1.85rem",
        lineHeight: 0.95,
        fontFamily: "VAGRoundedLT, serif-sans",
      },
      h6: {
        color: theme.palette.primary.dark,
        lineHeight: 0.95,
        fontFamily: "VAGRoundedLT, serif-sans",
      },
    },
  }),
  { factor: 3.5 }
);

export const componentCustomization = createTheme(typographyCustomization, {
  components: {
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontFamily: typographyCustomization.typography.fontFamily,
          h1: typographyCustomization.typography.h1,
          h2: typographyCustomization.typography.h2,
          h3: typographyCustomization.typography.h3,
          h4: typographyCustomization.typography.h4,
          h5: typographyCustomization.typography.h5,
          h6: typographyCustomization.typography.h6,
          "& h1 span, h2 span, h3 span, h4 span, h5 span, h6 span": {
            fontSize: "unset",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "VAGRoundedLT-Bold, serif-sans",
        },
        sizeSmall: {
          padding: theme.spacing(1, 3),
        },
        sizeMedium: {
          padding: theme.spacing(2, 4),
          fontSize: "1.1rem",
        },
        sizeLarge: {
          padding: theme.spacing(3, 6),
          fontSize: "1.25rem",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "VAGRoundedLT-Light, serif-sans",
        },
      },
    },
  },
});

export const baseStyleOverrides = createTheme(componentCustomization, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          textRendering: "optimizeLegibility",
        },
        a: {
          display: "inline-block",
          textDecoration: "none",
          fontFamily: "VAGRoundedLT-Light, serif-sans",
        },
        ul: {
          listStyleType: "square",
        },
        "&::selection, & *::selection": {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.dark,
        },
        "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
          width: theme.spacing(1),
        },
        "&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track": {
          background: theme.palette.primary.light,
          border: `${theme.spacing(0.5)}px solid ${
            theme.palette.primary.light
          }`,
          borderRadius: 0,
        },
        "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
          background: theme.palette.primary.dark,
          border: `${theme.spacing(0.5)}px solid ${
            theme.palette.primary.light
          }`,
          borderRadius: 0,
          transition: theme.transitions.create("all", {
            duration: theme.transitions.duration.complex,
            easing: theme.transitions.easing.easeOut,
          }),
        },
        "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
          {
            backgroundColor: darken(theme.palette.primary.dark, 0.18),
          },
      },
    },
    MuiLink: {
      defaultProps: {
        target: "_blank",
        rel: "noopener",
        underline: "hover",
      },
    },
  },
});

export default baseStyleOverrides;
