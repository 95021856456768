import type { MouseEvent } from "react";
import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import type { MainMenuProps } from "./mainMenu.types";
import { Link } from "../link";
import { Logo } from "../logo";
import { ContactButtons } from "../contactButtons";
import type { SubMenuType } from "../../hooks/useMainMenu";
import { useMainMenu } from "../../hooks/useMainMenu";
import { isCurrentPage } from "../../utils/isCurrentPage";

export const MainMenu = ({ sx }: MainMenuProps) => {
  const mainMenu = useMainMenu();
  const [submenu, setSubmenu] = useState<SubMenuType>(undefined);
  const [openMenu, setOpenMenu] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);

  const handleMouseOver = useCallback(
    (subMenu: SubMenuType, menuId: string) =>
      (event: MouseEvent<HTMLDivElement>) => {
        setOffset(event.currentTarget.offsetLeft);
        setSubmenu(subMenu);
        setOpenMenu(menuId);
      },
    []
  );

  const handleMouseOut = useCallback(() => {
    setSubmenu(undefined);
    setOpenMenu(null);
  }, []);

  return (
    <Box sx={sx} onMouseLeave={handleMouseOut} id="mainMenu">
      <Box
        sx={{
          backgroundColor: ({ palette }) => palette.common.white,
          paddingX: ({ spacing }) => spacing(2),
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Logo
          sx={{
            marginY: "auto",
            marginRight: 2,
            maxWidth: 90,
          }}
        />
        <Box>
          {mainMenu.map(({ target, content, subMenu, id }) => {
            const currentPage = isCurrentPage(target);
            return (
              <Link
                key={id}
                to={target ?? ""}
                wrapperProps={{
                  onMouseOver: handleMouseOver(subMenu, id),
                }}
                sx={{
                  boxShadow: ({ palette }) =>
                    currentPage
                      ? `inset 0 0 0 1px ${palette.primary.dark}`
                      : "none",
                  fontSize: {
                    xs: 14,
                    lg: 16,
                    xl: 18,
                  },
                  padding: ({ spacing }) => ({
                    xs: spacing(2),
                    lg: spacing(2, 3),
                    xl: spacing(2, 4),
                  }),
                  marginX: ({ spacing }) => ({
                    xs: spacing(0.25),
                    lg: spacing(0.5),
                  }),
                  transition: ({ transitions }) =>
                    transitions.create(["background-color", "color"]),
                  color: ({ palette }) =>
                    currentPage
                      ? palette.primary.dark
                      : palette.primary.contrastText,
                  backgroundColor: ({ palette }) =>
                    currentPage
                      ? "transparent"
                      : openMenu === id
                      ? palette.primary.dark
                      : palette.primary.main,
                  "&:hover": {
                    backgroundColor: ({ palette }) =>
                      currentPage ? "transparent" : palette.primary.dark,
                  },
                }}
              >
                {content}
              </Link>
            );
          })}
        </Box>
        <ContactButtons
          sx={{
            marginY: "auto",
            marginLeft: "auto",
          }}
        />
      </Box>

      <Collapse
        in={!!submenu}
        sx={{
          marginLeft: { xs: `${offset + 2}px`, lg: `${offset + 4}px` },
          width: "fit-content",
          backgroundColor: ({ palette }) => palette.primary.main,
          overflow: "hidden",
          ".MuiCollapse-wrapperInner": {
            display: "flex",
            flexDirection: {
              xs: "column",
              lg: "row",
            },
          },
        }}
      >
        {submenu?.map(({ target, content: label, id }) => {
          const currentPage = isCurrentPage(target);

          return (
            <Link
              key={id}
              to={target ?? ""}
              sx={{
                fontSize: {
                  xs: 14,
                  lg: 15,
                  xl: 16,
                },
                boxShadow: ({ palette }) =>
                  currentPage
                    ? `inset 0 0 0 1px ${palette.primary.dark}`
                    : "none",
                backgroundColor: ({ palette }) =>
                  currentPage ? palette.common.white : "transparent",
                padding: ({ spacing }) => ({
                  xs: spacing(2),
                  lg: spacing(2, 3),
                  xl: spacing(2, 4),
                }),
                color: ({ palette }) =>
                  currentPage
                    ? palette.primary.dark
                    : palette.primary.contrastText,
                transition: ({ transitions }) =>
                  transitions.create(["background-color", "color"]),
                "&:hover": {
                  backgroundColor: ({ palette }) =>
                    currentPage ? palette.common.white : palette.primary.dark,
                },
                width: "100%",
              }}
            >
              {label}
            </Link>
          );
        })}
      </Collapse>
    </Box>
  );
};
