import type { Maybe } from "../generic";

export const convertMailToLink = (email?: string | null) =>
  email ? `mailto:${email}` : ``;

export const convertNumberToLink = (number?: string | null) =>
  number ? number.replace(/0+/, "tel:+31").replace(/-|\s/g, "") : ``;

type AddressData = {
  straat: Maybe<string>;
  huisnummer: Maybe<string>;
  postcode: Maybe<string>;
  plaats: Maybe<string>;
};

export const convertToAddressString = ({
  straat,
  huisnummer,
  postcode,
  plaats,
}: AddressData) =>
  `${straat || ""} ${huisnummer || ""}, ${postcode || ""} ${plaats || ""}`;

export const contvertToMapsLink = (addressData: AddressData) =>
  `https://www.google.com/maps/place/${convertToAddressString(
    addressData
  ).replaceAll(" ", "+")}`;
