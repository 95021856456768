import { styled } from "@mui/material/styles";
import type { GatsbyImageProps } from "gatsby-plugin-image";
import { GatsbyImage } from "gatsby-plugin-image";
import type { SxProps, Theme } from "@mui/material/styles";

export interface ImageProps extends GatsbyImageProps {
  sx?: SxProps<Theme>;
}

export const Image = styled(GatsbyImage)``;
