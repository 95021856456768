import type {
  DetailedHTMLProps,
  AnchorHTMLAttributes,
  ReactNode,
  HTMLAttributes,
} from "react";
import React from "react";
import type { SxProps, Theme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import { Link as GatsbyLink } from "gatsby";
import MuiLink from "@mui/material/Link";
import { ButtonBase } from "@mui/material";
import { sxHelper } from "../utils/sx";

export interface LinkProps
  extends Omit<
    DetailedHTMLProps<
      AnchorHTMLAttributes<HTMLAnchorElement>,
      HTMLAnchorElement
    >,
    "ref"
  > {
  children?: ReactNode;
  sx?: SxProps<Theme>;
  to: string;
  activeStyle?: object;
  partiallyActive?: boolean;
  wrapperProps?: { sx?: SxProps<Theme> } & Partial<
    Omit<
      DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
      "ref"
    >
  >;
}

export const StyledLink = styled(GatsbyLink)``;

const startsWith = (match: string, ...compare: string[]) =>
  compare.reduce((acc, str) => (match.startsWith(str) ? true : acc), false);

const isExternalLink = (url: string) =>
  startsWith(url, "http", "tel:", "mailto:");

export const Link = ({
  to,
  activeStyle,
  partiallyActive,
  wrapperProps,
  ...props
}: LinkProps) => (
  <ButtonBase
    component="div"
    {...wrapperProps}
    sx={sxHelper({ display: "inline-block" }, wrapperProps?.sx)}
  >
    {isExternalLink(to) ? (
      <MuiLink href={to} {...props} />
    ) : (
      <StyledLink
        to={to}
        activeStyle={activeStyle}
        partiallyActive={partiallyActive}
        {...props}
      />
    )}
  </ButtonBase>
);
