import { useStaticQuery, graphql } from "gatsby";

export const useContactInfo = () => {
  const { wp } = useStaticQuery<Queries.ContactInfoQuery>(graphql`
    query ContactInfo {
      wp {
        contactInfo {
          email
          facebook
          huisnummer
          instagram
          latitude
          linkedin
          longitude
          pinterest
          plaats
          postcode
          straat
          telefoonnummer
          twitter
          youtube
        }
      }
    }
  `);

  return { ...wp?.contactInfo };
};
