import React from "react";
import Box from "@mui/material/Box";
import { Phone, Email } from "../icon-buttons";
import { convertMailToLink, convertNumberToLink } from "../../utils/convert";
import { useContactInfo } from "../../hooks/contactInfo";
import type { SxProps, Theme } from "@mui/material";

export const ContactButtons = ({ sx }: { sx?: SxProps<Theme> }) => {
  const contactInfo = useContactInfo();

  return (
    <Box sx={sx}>
      {contactInfo?.telefoonnummer ? (
        <Phone href={convertNumberToLink(contactInfo.telefoonnummer)} />
      ) : null}
      {contactInfo?.email ? (
        <Email href={convertMailToLink(contactInfo?.email)} />
      ) : null}
    </Box>
  );
};
