import React from "react";

// import components
import { StyledEngineProvider } from "@mui/material/styles";
import { ThemeProvider } from "./theme/provider";
import { Layout } from "./components/layout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// import types
import type { PageProps } from "gatsby";

const WrapRoot: React.FC<PageProps> = ({ children }) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Layout>{children}</Layout>
      </LocalizationProvider>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default WrapRoot;
