import { graphql, useStaticQuery } from "gatsby";

export const usePrivacyPage = () => {
  const { wpPage } = useStaticQuery<Queries.PrivacyPageQuery>(graphql`
    query PrivacyPage {
      wpPage(isPrivacyPage: { eq: true }) {
        id
        uri
        title
      }
    }
  `);

  return { ...wpPage };
};
