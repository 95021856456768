import React from "react";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import type { IconButtonProps } from "../iconButtonProps";

export const Phone = (props: IconButtonProps) => (
  <IconButton {...props}>
    <PhoneIcon />
  </IconButton>
);
